<template>
    <div>
        <h4>Course Author</h4>
        <div class="card">
            <div class="text-center">
                <img src="@/assets/img/user1.jpg" class="card-img-top img-fluid rounded-circle" alt="...">
            </div>
            <div class="card-header">
                <h5>{{ course.user_name }}</h5>
            </div>
            <div class="card-body">
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            </div>
        </div>
        <h4>Course Info</h4>
        <div class="card">
            <div class="card-body">
                <ul class="list-group list-group-flush text-start">
                    <li class="list-group-item">Level: <span class="text-end">Beginner</span></li>
                    <li class="list-group-item">Rating: 
                        <span>
                            <star-rating v-model:rating="rating" :increment="0.1" :read-only="true" :animate="true" v-bind:star-size="20"></star-rating>
                        </span>
                    </li>
                    <li class="list-group-item">Duration: <span class="text-end">2h 20m</span></li>
                    <li class="list-group-item">Updated: <span class="text-end">21 Jun 2019</span></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import StarRating from 'vue-star-rating'
export default {
    props:['course'],
    components: { StarRating, },
    data(){
        return {
            rating: 4.3,
        }
    },

}
</script>

<style>
.card-img-top{
    height: 100px;
    width: 100px;
}
</style>